import React, { useEffect, useState } from 'react';

const Input = ({
    value,
    onChange = () => {},
    placeholder,
    type,
    size = 'medium',
    referencia,
    onBlur
}) => {
    const sizeClasses = {
        small: 'text-sm py-1 px-2',
        medium: 'text-base py-2 px-3',
        large: 'text-lg py-3 px-4',
    };

    // Atualiza o estado sempre que o valor externo muda
    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const [inputValue, setInputValue] = useState(value || '');

    const handlePhoneMask = (e) => {
        let maskedValue = e.target.value.replace(/\D/g, '');

        if (maskedValue.length > 10) {
            maskedValue = maskedValue.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (maskedValue.length > 6) {
            maskedValue = maskedValue.replace(/^(\d{2})(\d{4})/, '($1) $2-');
        } else if (maskedValue.length > 2) {
            maskedValue = maskedValue.replace(/^(\d{2})/, '($1) ');
        }

        setInputValue(maskedValue);
        onChange(maskedValue);
    };

    const handleCepMask = (e) => {
        let maskedValue = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

        if (maskedValue.length > 5) {
            maskedValue = maskedValue.replace(/^(\d{5})(\d)/, '$1-$2'); // Adiciona o hífen
        }

        setInputValue(maskedValue);
        onChange(maskedValue);
    };

    const handleChange = (e) => {
        const rawValue = e.target.value;

        if (type === 'tel') {
            handlePhoneMask(e);
        } else if (type === 'cep') {
            handleCepMask(e);
        } else {
            setInputValue(rawValue);
            onChange(rawValue);
        }
    };

    return (
        <input
            type={type || 'text'}
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            ref={referencia}
            onBlur={onBlur}
            className={`${sizeClasses[size]} rounded-lg w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-sm`}
        />
    );
};

export default Input;
