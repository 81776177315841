import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faChevronRight,
    faChevronDown,
    faTrash,
    faPen,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
    faPenToSquare,
    faCircleXmark,
    faFloppyDisk,
    faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import { formatValue } from '../utils/formatValue';
import Modal from "../components/Modal.js";
import Input from "../components/Input.js";
import axiosInstance from '../axiosConfig';

function GestaoCardapio() {
    const [secoesAbertas, setSecoesAbertas] = useState({});
    const [produtos, setProdutos] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [categoriaEdit, setCategoriaEdit] = useState({ id: null, nome: "" });
    const [isModalAddProduct, setIsModalAddProduct] = useState(false);
    const [isModalAddCategory, setIsModalAddCategory] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const inputSearch = useRef(null);
    const inputCategoria = useRef(null);
    const inputEditCategoria = useRef(null);
    const [cateCreate, setCateCreate] = useState(0);
    const [editandoProduto, setEditandoProduto] = useState(false);
    const [idProdutoEdit, setIdProdutoEdit] = useState('');
    const [titulo, setTitulo] = useState('');
    const [produtoAtivo, setProdutoAtivo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState('');
    const [tipo, setTipo] = useState('');
    const [urlImagem, setUrlImagem] = useState('');

    const openModal = () => setIsModalAddProduct(true);

    const openModalEditProduto = (produto, categoria) => {
        setIsModalAddProduct(true);
        setCateCreate(categoria);
        setEditandoProduto(true);

        setIdProdutoEdit(produto.id);
        setTitulo(produto.titulo);
        setProdutoAtivo(produto.ativo);
        setDescricao(produto.descricao);
        setValor(produto.valor);
        setTipo(produto.tipo);
        setUrlImagem(produto.url_Imagem);
    }

    const openAddCategoria = () => setIsModalAddCategory(true);

    const closeModal = () => {
        setIsModalAddProduct(false);
        setIsModalAddCategory(false);
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        buscarCategorias();

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    async function buscarCategorias(filtro) {
        try {
            const url = filtro ? `Categoria/GetCategorias?nome=${filtro}` : 'Categoria/GetCategorias';
            const response = await axiosInstance.get(url);

            if (response.status === 200) {
                setCategorias(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const toggleSecao = async (id) => {
        setSecoesAbertas(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));

        // Se a seção está sendo aberta e os produtos ainda não foram buscados
        if (!produtos[id] && !secoesAbertas[id]) {
            try {
                const response = await axiosInstance.get(`/Produto/GetProdutosByCategoria?categoriaId=${id}`);
                setProdutos(prevProdutos => ({
                    ...prevProdutos,
                    [id]: response.data,
                }));
            } catch (error) {
                // msg erro
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        buscarCategorias(inputSearch.current.value);
    };

    const formCategoriaSubmit = (event) => {
        event.preventDefault();
        criarCategoria(inputCategoria.current.value);
    };

    async function editCategoriaSubmit(id, categoria) {
        let novoNome = inputEditCategoria.current.value;

        if (novoNome === categoria)
            return setCategoriaEdit({ id: null, nome: "" });

        try {
            const response = await axiosInstance.put("Categoria/" + id, { Nome: novoNome });

            if (response.status === 204) {
                // msg sucesso

                setCategorias(prevCategorias =>
                    prevCategorias.map(categoria =>
                        categoria.id === categoriaEdit.id ? categoriaEdit : categoria
                    )
                );
                setCategoriaEdit({ id: null, nome: "" });
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function inativarCategoriaSubmit(id, status) {
        try {
            const response = await axiosInstance.put("Categoria/AlterarStatusCategoria/" + id, { Ativo: status });

            if (response.status === 204) {
                // msg sucesso

                setCategorias(prevCategorias =>
                    prevCategorias.map(categoria =>
                        categoria.id === id ? { ...categoria, ativo: status } : categoria
                    )
                );
                setCategoriaEdit({ id: null, nome: "" });
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function criarCategoria(nome) {
        try {
            const response = await axiosInstance.post("Categoria/NovaCategoria", { Nome: nome });

            if (response.status === 201) {
                // msg sucesso
                closeModal();

                const novaCategoria = {
                    id: response.data,
                    nome: nome,
                    ativo: true,
                };

                setCategorias([...categorias, novaCategoria]);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleCreateProduct = async () => {
        const newProduct = { titulo, descricao, valor, tipo, urlImagem, categoria: cateCreate };
        try {
            const response = await axiosInstance.post('Produto', newProduct);
            if (response.status === 201) {
                closeModal();

                const novoProduto = {
                    id: response.data,
                    titulo,
                    descricao,
                    valor,
                    tipo,
                    urlImagem,
                    categoria: cateCreate
                };

                setProdutos(prevProdutos => ({
                    ...prevProdutos,
                    [cateCreate]: [...(prevProdutos[cateCreate] || []), novoProduto], // Adicionar novo produto ao array existente
                }));

                setTitulo('');
                setDescricao('');
                setValor('');
                setTipo('');
                setUrlImagem('');
                setCateCreate(0);
                setEditandoProduto(false);
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleUpdateProduct = async (id) => {
        const newProduct = { id, titulo, descricao, valor, tipo, urlImagem, categoria: cateCreate };
        try {
            const response = await axiosInstance.put('Produto/' + id, newProduct);
            if (response.status === 204) {
                closeModal();

                const produtoEdit = {
                    id,
                    titulo,
                    descricao,
                    ativo: produtoAtivo,
                    valor,
                    tipo,
                    url_Imagem: urlImagem,
                    categoria: cateCreate
                };

                setProdutos(prevProdutos => ({
                    ...prevProdutos,
                    [cateCreate]: prevProdutos[cateCreate].map(produto =>
                        produto.id === produtoEdit.id ? produtoEdit : produto
                    )
                }));

                setTitulo('');
                setDescricao('');
                setValor('');
                setTipo('');
                setUrlImagem('');
                setCateCreate(0);
            }
        } catch (error) {
            // msg erro
        }
    };

    const alterarStatusProduto = async (produtoId, categoriaId, status) => {
        try {
            const response = await axiosInstance.put(`/Produto/AlterarStatusProduto/${produtoId}`, { Ativo: status });
            if (response.status === 204) {
                setProdutos(prevProdutos => {
                    const updatedProducts = [...prevProdutos[categoriaId]];
                    const index = updatedProducts.findIndex(produto => produto.id === produtoId);
                    if (index !== -1) {
                        updatedProducts[index] = { ...updatedProducts[index], ativo: status };
                    }
                    return {
                        ...prevProdutos,
                        [categoriaId]: updatedProducts
                    };
                });
            }
        } catch (error) {
            // msg erro
        }
    };


    return (
        <>
            <Modal isOpen={isModalAddProduct} onClose={closeModal}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">{!editandoProduto ? "Novo Produto" : "Editar Produto"}</span>
                    <button onClick={closeModal}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
                <div className="space-y-2">
                    <div className="space-x-2 flex">
                        <Input placeholder="Informe o título" size="medium" value={titulo} onChange={(e) => setTitulo(e)} />
                        <Input placeholder="Informe a descrição" size="medium" value={descricao} onChange={(e) => setDescricao(e)} />
                    </div>
                    <div className="space-x-2 flex">
                        <Input type={"number"} placeholder="Informe o valor" size="medium" value={valor} onChange={(e) => setValor(e)} />
                        <Input placeholder="Informe o tipo" size="medium" value={tipo} onChange={(e) => setTipo(e)} />
                    </div>
                    <div className="space-x-2 flex">
                        <Input placeholder="Informe a url da imagem" size="medium" value={urlImagem} onChange={(e) => setUrlImagem(e)} />
                    </div>
                </div>
                <div className="flex items-center justify-between space-x-2 mt-3">
                    <SecondaryButton onClick={closeModal}>Cancelar</SecondaryButton>
                    {!editandoProduto ? <PrimaryButton onClick={handleCreateProduct}>Cadastrar</PrimaryButton> : <PrimaryButton onClick={() => handleUpdateProduct(idProdutoEdit)}>Editar</PrimaryButton>}
                </div>
            </Modal>

            <Modal isOpen={isModalAddCategory} onClose={closeModal}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">Nova Categoria</span>
                    <button className="" onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
                <form onSubmit={formCategoriaSubmit}>
                    <div className="space-y-2">
                        <div className="space-x-2 flex">
                            <Input placeholder="Informe o nome da categoria" size="medium" referencia={inputCategoria} />
                        </div>
                    </div>
                    <div className="flex items-center justify-between space-x-2 mt-3">
                        <SecondaryButton onClick={() => closeModal()}>
                            Cancelar
                        </SecondaryButton>
                        <PrimaryButton>
                            Cadastrar
                        </PrimaryButton>
                    </div>
                </form>
            </Modal>

            <div className="p-5 text-black">
                <div className="flex flex-wrap justify-between">
                    <div className="flex items-center">
                        <span className="text-xl font-semibold">Cardápio</span>
                    </div>
                    <div className="flex space-x-2 mb-2">
                        <div className="relative inline-block text-left">
                            <PrimaryButton
                                onClick={toggleDropdown}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-xl" />
                            </PrimaryButton>

                            {isOpen && (
                                <div
                                    ref={dropdownRef}
                                    className="absolute mt-2 w-80 rounded-[10px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                    style={{ top: '100%', right: '0' }}
                                >
                                    <div className="p-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <div className="mb-2 text-center">
                                            <span className="text-lg font-semibold">Filtros:</span>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div
                                                className="text-black px-2 items-center rounded-[10px] flex h-10 my-3 border border-gray-300"
                                                style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.05)', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)' }}
                                            >
                                                <div className="items-center w-full h-10 flex">
                                                    <input
                                                        ref={inputSearch}
                                                        type="text"
                                                        placeholder="Categoria"
                                                        className="text-md placeholder-black bg-transparent border-none focus:outline-none w-full h-10"
                                                    />
                                                </div>
                                            </div>
                                            <PrimaryButton>
                                                Buscar
                                            </PrimaryButton>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                        <PrimaryButton onClick={() => openAddCategoria()}>
                            Nova Categoria
                        </PrimaryButton>
                    </div>
                </div>

                {categorias.filter(categoria => categoria.ativo).map(categoria => (
                    <div key={categoria.id} className="bg-white border border-gray-300 rounded-[10px] py-4 px-6 my-2">
                        <div className='flex justify-between space-x-2'>
                            <div className={`text-start space-x-3 flex items-center cursor-pointer select-none`} onDoubleClick={() => setCategoriaEdit(categoria)}>
                                {categoriaEdit.id !== categoria.id ?
                                    <div className="space-x-2">
                                        <span className="font-medium text-xl">{categoria.nome}</span>
                                        <FontAwesomeIcon icon={faPenToSquare} className="text-xl" onClick={() => setCategoriaEdit(categoria)} />
                                    </div>
                                    :
                                    <div className="flex items-center space-x-2">
                                        <div className="w-1/4">
                                            <button className="text-white font-semibold py-2 px-4 rounded-lg w-full bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none shadow-md transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-95" onClick={() => inativarCategoriaSubmit(categoria.id, false)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="text-xl" />
                                            </button>
                                        </div>
                                        <Input
                                            placeholder="Nome da Categoria"
                                            size="medium"
                                            value={categoriaEdit.nome}
                                            referencia={inputEditCategoria}
                                            onChange={(e) => {
                                                setCategoriaEdit(prevState => ({
                                                    ...prevState,
                                                    nome: e
                                                }));
                                            }}
                                        />
                                        <div className="w-1/4">
                                            <PrimaryButton onClick={() => editCategoriaSubmit(categoria.id, categoria.nome)}>
                                                <FontAwesomeIcon icon={faFloppyDisk} className="text-xl" />
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="text-end space-x-2">
                                <button className={`py-1 px-3 bg-blue-700 text-white rounded-[10px] justify-between space-x-2 hover:bg-blue-800 transition-all duration-500 ease-out ${!secoesAbertas[categoria.id] && 'hidden'}`} onClick={() => { openModal(); setCateCreate(categoria.id) }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                    <span>
                                        Adicionar Produto
                                    </span>
                                </button>
                                <button className="w-8 py-1 border border-gray-400 rounded-[10px]" onClick={() => toggleSecao(categoria.id)}>
                                    <FontAwesomeIcon icon={!secoesAbertas[categoria.id] ? faChevronRight : faChevronDown} />
                                </button>
                            </div>
                        </div>
                        <div className={`transition-all duration-500 ease-out ${secoesAbertas[categoria.id] ? 'h-auto opacity-100 mt-4' : 'h-0 opacity-0 mt-0'}`}>
                            {secoesAbertas[categoria.id] &&
                                <>
                                    {produtos[categoria.id] === undefined ? (
                                        <div>Carregando...</div>
                                    ) : produtos[categoria.id].length === 0 ? (
                                        <div>Nenhum produto cadastrado</div>
                                    ) : (
                                        produtos[categoria.id].filter(produto => produto.ativo).map(produto => (
                                            <div key={produto.id} className="flex space-x-2 border justify-between rounded-[10px] py-2 px-4 mb-2">
                                                <div className="flex items-center space-x-4">
                                                    <img src={produto.url_Imagem} alt="Lanche" className="w-24 h-20 rounded-[10px] object-cover" />
                                                    <div className="grid">
                                                        <span className="text-lg font-medium">{produto.titulo}</span>
                                                        <span className="text-md font-normal">{formatValue(produto.valor)}</span>
                                                    </div>
                                                </div>
                                                <div className="space-x-2 flex items-center">
                                                    <button className="w-10 py-2 bg-yellow-600 hover:bg-yellow-700 text-white rounded-[10px]" onClick={() => openModalEditProduto(produto, categoria.id)}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button className="w-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-700 text-white rounded-[10px]">
                                                        <FontAwesomeIcon icon={faTrash} onClick={() => alterarStatusProduto(produto.id, categoria.id, false)} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            }

                            <span className="text-lg font-medium">Produtos Inativados:</span>

                            {secoesAbertas[categoria.id] &&
                                <>
                                    {produtos[categoria.id] === undefined ? (
                                        <div>Carregando...</div>
                                    ) : produtos[categoria.id].length === 0 ? (
                                        <div>Nenhum produto cadastrado</div>
                                    ) : (
                                        produtos[categoria.id].filter(produto => !produto.ativo).map(produto => (
                                            <div key={produto.id} className="flex space-x-2 border justify-between rounded-[10px] py-2 px-4 mb-2">
                                                <div className="flex items-center space-x-4">
                                                    <img src={produto.url_Imagem} alt="Lanche" className="w-24 h-20 rounded-[10px] object-cover" />
                                                    <div className="grid">
                                                        <span className="text-lg font-medium">{produto.titulo}</span>
                                                        <span className="text-md font-normal">{formatValue(produto.valor)}</span>
                                                    </div>
                                                </div>
                                                <div className="space-x-2 flex items-center">
                                                    <button className="w-10 py-2 bg-yellow-600 hover:bg-yellow-700 text-white rounded-[10px]" onClick={() => openModalEditProduto(produto, categoria.id)}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button className="w-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-700 text-white rounded-[10px]">
                                                        <FontAwesomeIcon icon={faTrash} onClick={() => alterarStatusProduto(produto.id, categoria.id, true)} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            }
                        </div>
                    </div>
                ))}

                <div className="mt-6">
                    <span className="text-xl font-medium">Categorias Inativas:</span>
                    {categorias.filter(categoria => !categoria.ativo).map(categoria => (
                        <div key={categoria.id} className="bg-white border border-gray-300 rounded-[10px] py-4 px-6 my-2">
                            <div className='flex justify-between space-x-2'>
                                <div className={`flex items-center select-none`}>
                                    <span className="font-medium text-xl">{categoria.nome}</span>
                                </div>
                                <div className="text-end space-x-2">
                                    <button className={`py-1 px-3 bg-blue-700 text-white rounded-[10px] justify-between space-x-2 hover:bg-blue-800 transition-all duration-500 ease-out`} onClick={() => inativarCategoriaSubmit(categoria.id, true)}>
                                        <span>
                                            Reativar Categoria
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default GestaoCardapio;


/*

CRUD PRODUTOS

C   -   Feito
R   -   Feito
U   -   
D   -    

*/