import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";

function App() {
    return (
        <>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap" rel="stylesheet"></link>

            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </>
    );
}

export default App;