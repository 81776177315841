import React from 'react';

const Modal = ({ isOpen, onClose, children, zIndex = 30 }) => {
    if (!isOpen) return null;

    return (
        <div className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`} style={{ zIndex }}>
            <div className="w-5/6 md:w-2/6 p-6 rounded-[20px] shadow-lg bg-white">
                {children}
            </div>
        </div>

    );
};

export default Modal;