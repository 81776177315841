import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMotorcycle,
    faChevronDown,
    faMoneyBillWave,
    faUser,
    faChevronRight,
    faStreetView,
    faBasketShopping,
    faMagnifyingGlass,
    faGear,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCircleXmark
} from "@fortawesome/free-regular-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../components/PrimaryButton";
import axiosInstance from '../axiosConfig';
import { formatValue } from "../utils/formatValue";
import { formatTelefone } from "../utils/formatTelefone";
import Modal from "../components/Modal";
import SecondaryButton from "../components/SecondaryButton";
import Input from "../components/Input";
import { useNavigate } from "react-router-dom";

function MeusPedidos() {
    const [secoesAbertas, setSecoesAbertas] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [pedidos, setPedidos] = useState([]);
    const [isModalTimePrepare, setIsModalTimePrepare] = useState(false);
    const [pedidosPendentes, setPedidosPendentes] = useState([]);
    const [pedidosPreparo, setPedidosPreparo] = useState([]);
    const [pedidosProntos, setPedidosProntos] = useState([]);
    const [tempoPreparo, setTempoPreparo] = useState('');
    const dropdownRef = useRef(null);
    const inputSearchClient = useRef(null);
    const inputSearchOrder = useRef(null);
    const navigate = useNavigate();

    const toggleDropdown = () => setIsOpen(!isOpen);

    const openModal = () => setIsModalTimePrepare(true);

    const closeModal = () => {
        setIsModalTimePrepare(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        const fetchPedidos = async () => {
            if (inputSearchClient.current === null && inputSearchOrder.current == null) buscarPedidos();
        };

        fetchPedidos();
        const intervalId = setInterval(fetchPedidos, 30000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        consultarTempoPreparo();

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (pedidos.length === 0) {
            setPedidosPendentes([]);
            setPedidosPreparo([]);
            setPedidosProntos([]);
        };

        setPedidosPendentes(pedidos.filter(pedido => pedido.status === 1));
        setPedidosPreparo(pedidos.filter(pedido => pedido.status === 2));
        setPedidosProntos(pedidos.filter(pedido => pedido.status === 3));
    }, [pedidos]);

    const toggleSecao = (id) => {
        setSecoesAbertas(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    async function updateStatus(id) {
        try {
            const response = await axiosInstance.put("Pedidos/AvancarStatus/" + id);

            if (response.status === 204) {
                setPedidos((prevPedidos) =>
                    prevPedidos.map((pedido) =>
                        pedido.id === id ? { ...pedido, status: pedido.status + 1 } : pedido
                    )
                );
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        buscarPedidos(inputSearchClient.current.value, inputSearchOrder.current.value);
    };

    async function consultarTempoPreparo(id) {
        try {
            const response = await axiosInstance.get("Configs/TempoPreparo");

            if (response.status === 200) {
                setTempoPreparo(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function handleAttTempoPreparo() {
        try {
            const response = await axiosInstance.put("Configs/TempoPreparo", { Tempo: tempoPreparo });
            if (response.status === 204) {
                setIsModalTimePrepare(false);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function buscarPedidos(cliente, pedido) {
        try {
            const params = {};

            if (cliente !== "") {
                params.cliente = cliente;
            }

            if (pedido !== "") {
                params.pedido = pedido;
            }

            const response = await axiosInstance.get("Pedidos/PedidosAbertos", { params });

            if (response.status === 200) {
                setPedidos(response.data);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    return (
        <>
            <Modal isOpen={isModalTimePrepare} onClose={closeModal}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">Tempo de Preparo</span>
                    <button onClick={closeModal}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
                <div className="space-y-2">
                    <div className="space-x-2 flex">
                        <Input placeholder="Informe o tempo de preparo" size="medium" value={tempoPreparo} onChange={(e) => setTempoPreparo(e)} />
                    </div>
                </div>
                <div className="flex items-center justify-between space-x-2 mt-3">
                    <SecondaryButton onClick={closeModal}>Cancelar</SecondaryButton>
                    <PrimaryButton onClick={() => handleAttTempoPreparo()}>Atualizar</PrimaryButton>
                </div>
            </Modal>

            <div className="p-5 text-black">
                <div className="mb-3">
                    <div className="flex flex-wrap justify-end">
                        <div className="flex space-x-2">
                            <div className="relative inline-block text-left">
                                {/* <PrimaryButton
                                    onClick={toggleDropdown}
                                >
                                    <FontAwesomeIcon icon={faMagnifyingGlass} className="text-xl" />
                                </PrimaryButton> */}

                                {isOpen && (
                                    <div
                                        ref={dropdownRef}
                                        className="absolute mt-2 w-80 rounded-[10px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                        style={{ top: '100%', right: '0' }}
                                    >
                                        <div className="p-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-2 text-center">
                                                    <span className="text-lg font-semibold">Filtros:</span>
                                                </div>
                                                <div
                                                    className="text-black px-2 items-center rounded-[10px] flex h-10 border border-gray-300"
                                                    style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.05)', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)' }}
                                                >
                                                    <div className="items-center w-full h-10 flex">
                                                        <input
                                                            ref={inputSearchClient}
                                                            type="text"
                                                            placeholder="Cliente"
                                                            className="text-md placeholder-black bg-transparent border-none focus:outline-none w-full h-10"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-black px-2 items-center rounded-[10px] flex h-10 my-3 border border-gray-300"
                                                    style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.05)', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)' }}
                                                >
                                                    <div className="items-center w-full h-10 flex">
                                                        <input
                                                            ref={inputSearchOrder}
                                                            type="text"
                                                            placeholder="Pedido"
                                                            className="text-md placeholder-black bg-transparent border-none focus:outline-none w-full h-10"
                                                        />
                                                    </div>
                                                </div>
                                                <PrimaryButton>
                                                    Buscar
                                                </PrimaryButton>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <PrimaryButton>
                                <FontAwesomeIcon icon={faGear} className="text-xl" onClick={() => openModal()} />
                            </PrimaryButton>
                            <PrimaryButton onClick={() => navigate('/novopedido')}>
                                Novo Pedido
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-4 items-start">
                    <div className="bg-amber-500 w-full rounded-[10px] p-2 text-white">
                        <span className="text-xl block text-center">
                            Pedidos Pendentes ({pedidosPendentes.length.toString().padStart(2, '0')})
                        </span>

                        {pedidosPendentes.map(pedido => (
                            <div className="mt-2 rounded-[10px] overflow-hidden">
                                <div className="py-3 px-4 bg-amber-700 bg-opacity-35">
                                    <div className="flex justify-between mb-2">
                                        <div className="items-center flex">
                                            <FontAwesomeIcon
                                                className="text-md mr-2 rounded-[10px] p-2 bg-amber-700 bg-opacity-50"
                                                icon={faStreetView}
                                            />
                                            <span className="text-lg">Pedido {pedido.id.toString().padStart(6, '0')}</span>
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon className="text-lg mr-2" icon={faClock} />
                                                <span className="text-md">{new Date(pedido.dtCriacao).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                            </div>
                                            <button className="w-8 py-1 bg-amber-700 bg-opacity-50 rounded-[10px]" onClick={() => toggleSecao(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={`transition-all duration-500 ease-out overflow-hidden ${secoesAbertas[pedido.id] ? 'h-auto opacity-100 py-3 px-4 bg-amber-700 bg-opacity-35' : 'h-0 w-0 opacity-100 bg-amber-700 bg-opacity-35'}`}>
                                    {pedido.tipoEntrega === "Retirada" &&
                                        <div className="pb-4">
                                            <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faUser}
                                                />
                                                Retirada
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                        </div>
                                    }
                                    {pedido.tipoEntrega === "Delivery" &&
                                        <div className="pb-4">
                                            <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faMotorcycle}
                                                />
                                                Delivery
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                            <span className="block text-center">{pedido.cliente.logradouro}, Nº {pedido.cliente.numero}, {pedido.cliente.bairro}, {pedido.cliente.cidade} - {pedido.cliente.estado}</span>
                                        </div>
                                    }

                                    <div className="pb-2 border-amber-700 overflow-hidden items-center space-y-3">
                                        <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-3 items-center flex justify-center">
                                            <FontAwesomeIcon
                                                className="text-lg mr-2 text-center"
                                                icon={faBasketShopping}
                                            />
                                            Produtos
                                        </div>

                                        {pedido.itensPedido.map(item => (
                                            <div className="flex space-x-4 items-start">
                                                <div className="max-w-16 w-full h-16">
                                                    <img src={item.produto.url_Imagem} alt="Imagem Produto" className="object-cover h-16 w-16 rounded-[10px]" />
                                                </div>
                                                <div className="w-full">
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <span className="text-lg">
                                                                {item.quantidade + " - " + item.produto.titulo}
                                                            </span>
                                                        </div>
                                                        <div className="max-w-17">
                                                            <span className="text-lg">{formatValue(item.valorTotal)}</span>
                                                        </div>
                                                    </div>
                                                    {item.observacao !== "" &&
                                                        <span className="text-sm">Obs: {item.observacao}</span>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* {pedido.tipoEntrega !== "Balcão" &&
                                        <div className="border-t-2 pt-2 border-amber-700">
                                            <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faMoneyBillWave}
                                                />
                                                Forma de Pagamento
                                            </div>
                                            <span className="block text-center">Cartão de Débito Mastercard</span>
                                        </div>
                                    } */}
                                </div>

                                <div className="pb-3 px-4 bg-amber-700 bg-opacity-35">
                                    <div className="flex justify-between">
                                        <div className="items-center flex space-x-2">
                                            <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2"
                                                    icon={faMoneyBillWave}
                                                />
                                                <span className="text-md">{formatValue(pedido.valorTotal)}</span>
                                            </div>
                                            {pedido.tipoEntrega === "Balcão" &&
                                                <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Mesa {pedido.numeroMesa}</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Retirada" &&
                                                <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Retirada</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Delivery" &&
                                                <div className="bg-amber-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Delivery</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <button className="w-8 py-1 bg-amber-700 bg-opacity-50 rounded-[10px] cursor-pointer" onClick={() => updateStatus(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="bg-orange-500 w-full rounded-[10px] p-2 text-white">
                        <span className="text-xl block text-center">
                            Pedidos em Preparo ({pedidosPreparo.length.toString().padStart(2, '0')})
                        </span>
                        {pedidosPreparo.map(pedido => (
                            <div className="mt-2 rounded-[10px] overflow-hidden">
                                <div className="py-3 px-4 bg-orange-700 bg-opacity-35">
                                    <div className="flex justify-between mb-2">
                                        <div className="items-center flex">
                                            <FontAwesomeIcon
                                                className="text-md mr-2 rounded-[10px] p-2 bg-orange-700 bg-opacity-50"
                                                icon={faStreetView}
                                            />
                                            <span className="text-lg">Pedido {pedido.id.toString().padStart(6, '0')}</span>
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon className="text-lg mr-2" icon={faClock} />
                                                <span className="text-md">{new Date(pedido.dtCriacao).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                            </div>
                                            <button className="w-8 py-1 bg-orange-700 bg-opacity-50 rounded-[10px]" onClick={() => toggleSecao(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={`transition-all duration-500 ease-out overflow-hidden ${secoesAbertas[pedido.id] ? 'h-auto opacity-100 py-3 px-4 bg-amber-700 bg-opacity-35' : 'h-0 w-0 opacity-0 bg-amber-700 bg-opacity-35'}`}>
                                    {pedido.tipoEntrega === "Retirada" &&
                                        <div className="pb-4">
                                            <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faUser}
                                                />
                                                Retirada
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                        </div>
                                    }
                                    {pedido.tipoEntrega === "Delivery" &&
                                        <div className="pb-4">
                                            <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faMotorcycle}
                                                />
                                                Delivery
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                            <span className="block text-center">{pedido.cliente.logradouro}, Nº {pedido.cliente.numero}, {pedido.cliente.bairro}, {pedido.cliente.cidade} - {pedido.cliente.estado}</span>
                                        </div>
                                    }

                                    <div className="pb-2 border-orange-700 overflow-hidden items-center space-y-3">
                                        <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-3 items-center flex justify-center">
                                            <FontAwesomeIcon
                                                className="text-lg mr-2 text-center"
                                                icon={faBasketShopping}
                                            />
                                            Produtos
                                        </div>

                                        {pedido.itensPedido.map(item => (
                                            <div className="flex space-x-4 items-start">
                                                <div className="max-w-16 w-full h-16">
                                                    <img src={item.produto.url_Imagem} alt="Imagem Produto" className="object-cover h-16 w-16 rounded-[10px]" />
                                                </div>
                                                <div className="w-full">
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <span className="text-lg">
                                                                {item.quantidade + " - " + item.produto.titulo}
                                                            </span>
                                                        </div>
                                                        <div className="max-w-17">
                                                            <span className="text-lg">{formatValue(item.valorTotal)}</span>
                                                        </div>
                                                    </div>
                                                    {item.observacao !== "" &&
                                                        <span className="text-sm">Obs: {item.observacao}</span>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* {pedido.tipoEntrega !== "Balcão" &&
                                        <div className="border-t-2 pt-2 border-orange-700">
                                            <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faMoneyBillWave}
                                                />
                                                Forma de Pagamento
                                            </div>
                                            <span className="block text-center">Cartão de Débito Mastercard</span>
                                        </div>
                                    } */}
                                </div>

                                <div className="pb-3 px-4 bg-orange-700 bg-opacity-35">
                                    <div className="flex justify-between">
                                        <div className="items-center flex space-x-2">
                                            <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2"
                                                    icon={faMoneyBillWave}
                                                />
                                                <span className="text-md">{formatValue(pedido.valorTotal)}</span>
                                            </div>
                                            {pedido.tipoEntrega === "Balcão" &&
                                                <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Mesa {pedido.numeroMesa}</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Retirada" &&
                                                <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Retirada</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Delivery" &&
                                                <div className="bg-orange-700 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Delivery</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <button className="w-8 py-1 bg-orange-700 bg-opacity-50 rounded-[10px] cursor-pointer" onClick={() => updateStatus(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="bg-green-700 w-full rounded-[10px] p-2 text-white">
                        <span className="text-xl block text-center">
                            Pedidos Prontos ({pedidosProntos.length.toString().padStart(2, '0')})
                        </span>
                        {pedidosProntos.map(pedido => (
                            <div className="mt-2 rounded-[10px] overflow-hidden">
                                <div className="py-3 px-4 bg-green-900 bg-opacity-35">
                                    <div className="flex justify-between mb-2">
                                        <div className="items-center flex">
                                            <FontAwesomeIcon
                                                className="text-md mr-2 rounded-[10px] p-2 bg-green-900 bg-opacity-50"
                                                icon={faStreetView}
                                            />
                                            <span className="text-lg">Pedido {pedido.id.toString().padStart(6, '0')}</span>
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon className="text-lg mr-2" icon={faClock} />
                                                <span className="text-md">{new Date(pedido.dtCriacao).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                            </div>
                                            <button className="w-8 py-1 bg-green-900 bg-opacity-50 rounded-[10px]" onClick={() => toggleSecao(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={`transition-all duration-500 ease-out overflow-hidden ${secoesAbertas[pedido.id] ? 'h-auto opacity-100 py-3 px-4 bg-green-900 bg-opacity-35' : 'h-0 w-0 opacity-0 bg-green-900 bg-opacity-35'}`}>
                                    {pedido.tipoEntrega === "Retirada" &&
                                        <div className="pb-4">
                                            <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faUser}
                                                />
                                                Retirada
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                        </div>
                                    }
                                    {pedido.tipoEntrega === "Delivery" &&
                                        <div className="pb-4">
                                            <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faUser}
                                                />
                                                Delivery
                                            </div>
                                            <span className="block text-center">{pedido.cliente.nome}: {formatTelefone(pedido.cliente.telefone)}</span>
                                            <span className="block text-center">{pedido.cliente.logradouro}, Nº {pedido.cliente.numero}, {pedido.cliente.bairro}, {pedido.cliente.cidade} - {pedido.cliente.estado}</span>
                                        </div>
                                    }

                                    <div className="pb-2 border-green-900 overflow-hidden items-center space-y-3">
                                        <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 mb-3 items-center flex justify-center">
                                            <FontAwesomeIcon
                                                className="text-lg mr-2 text-center"
                                                icon={faBasketShopping}
                                            />
                                            Produtos
                                        </div>

                                        {pedido.itensPedido.map(item => (
                                            <div className="flex space-x-4 items-start">
                                                <div className="max-w-16 w-full h-16">
                                                    <img src={item.produto.url_Imagem} alt="Imagem Produto" className="object-cover h-16 w-16 rounded-[10px]" />
                                                </div>
                                                <div className="w-full">
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <span className="text-lg">
                                                                {item.quantidade + " - " + item.produto.titulo}
                                                            </span>
                                                        </div>
                                                        <div className="max-w-17">
                                                            <span className="text-lg">{formatValue(item.valorTotal)}</span>
                                                        </div>
                                                    </div>
                                                    {item.observacao !== "" &&
                                                        <span className="text-sm">Obs: {item.observacao}</span>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* {pedido.tipoEntrega !== "Balcão" &&
                                        <div className="border-t-2 pt-2 border-green-900">
                                            <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 mb-2 items-center flex justify-center">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2 text-center"
                                                    icon={faMoneyBillWave}
                                                />
                                                Forma de Pagamento
                                            </div>
                                            <span className="block text-center">Cartão de Débito Mastercard</span>
                                        </div>
                                    } */}
                                </div>

                                <div className="pb-3 px-4 bg-green-900 bg-opacity-35">
                                    <div className="flex justify-between">
                                        <div className="items-center flex space-x-2">
                                            <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                <FontAwesomeIcon
                                                    className="text-lg mr-2"
                                                    icon={faMoneyBillWave}
                                                />
                                                <span className="text-md">{formatValue(pedido.valorTotal)}</span>
                                            </div>
                                            {pedido.tipoEntrega === "Balcão" &&
                                                <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Mesa {pedido.numeroMesa}</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Retirada" &&
                                                <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Retirada</span>
                                                </div>
                                            }
                                            {pedido.tipoEntrega === "Delivery" &&
                                                <div className="bg-green-900 bg-opacity-50 rounded-[10px] py-1 px-2 items-center flex">
                                                    <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />
                                                    <span className="text-md">Delivery</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="items-center flex space-x-1">
                                            <button className="w-8 py-1 bg-green-900 bg-opacity-50 rounded-[10px] cursor-pointer" onClick={() => updateStatus(pedido.id)}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MeusPedidos;
