import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Auth from '../services/AuthService.js';
import axiosInstance from '../axiosConfig.js';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [aberto, setAberto] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        buscarStatusLoja();

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const alternarStatus = async () => {
        try {
            const response = await axiosInstance.put('Configs/status', { "aberto": !aberto });
            setAberto(response.data);
        } catch (error) {
            alert("Erro ao atualizar status.");
        }
    };

    const buscarStatusLoja = async () => {
        try {
            const response = await axiosInstance.get('Configs/status');
            setAberto(response.data);
        } catch (error) {
            alert("Erro ao atualizar status.");
        }
    };

    return (
        <div className="bg-white shadow p-2 flex justify-between items-center h-16">
            <div className=""></div>
            {/* <div>
                <span>Mensagens Rápidas (Lançamento SaaS bla bla bla...)</span>
            </div> */}
            <div className="flex space-x-4 items-center">
                <div ref={dropdownRef} className="relative">
                    <div
                        className="flex items-center cursor-pointer"
                        onClick={toggleDropdown}
                    >
                        <img
                            src={localStorage.getItem('restaurante_url')}
                            alt="Logo da Loja"
                            className="w-12 h-12 rounded-full"
                        />
                        <div className="ml-2 font-semibold">
                            <div className="flex items-center">
                                {localStorage.getItem('restaurante')}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 ml-1 text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </div>
                            {aberto ?
                                <div className="bg-green-700 px-3 rounded-full text-center">
                                    <span className="text-white text-sm">Aberto</span>
                                </div> :
                                <div className="bg-red-700 px-3 rounded-full text-center">
                                    <span className="text-white text-sm">Fechado</span>
                                </div>
                            }
                        </div>
                    </div>
                    {isOpen && (
                        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                            <ul className="py-1 text-sm text-gray-700">
                                <li>
                                    <button
                                        onClick={() => alternarStatus()}
                                        className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-500 mr-2"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17 16l4-4m0 0l-4-4m4 4H7"
                                            />
                                        </svg>
                                        {!aberto ? "Abrir Loja" : "Fechar Loja"}
                                    </button>
                                    <button
                                        onClick={() => Auth.logout()}
                                        className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-500 mr-2"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17 16l4-4m0 0l-4-4m4 4H7"
                                            />
                                        </svg>
                                        Sair
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                {/* <a href="#" className="text-black border-2 border-gray-400 p-2 rounded-[10px] hover:bg-gray-200 transition duration-200">
                    Link Cardápio
                </a> */}
            </div>
        </div>
    );
};

export default Navbar;
