import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import routes from "../routesConfig";

const Sidebar = ({ children }) => {
    const [isOpen, setIsSidebarOpen] = useState(true);
    const [currentPath, setCurrentPath] = useState('/');
    const location = useLocation();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isOpen);
    };

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    return (
        <div className="flex">
            {/* Sidebar */}
            <div
                className={`border-r border-gray-200 fixed inset-y-0 left-0 w-64 bg-white py-3 transform ${isOpen ? 'translate-x-0' : '-translate-x-48 hover:translate-x-0'} transition-transform duration-200 ease-in-out z-10 overflow-y-auto overflow-x-hidden`}
            >
                <div className={`items-center justify-between h-8 flex mb-5 px-3`}>
                    <img src={localStorage.getItem('restaurante_url')} alt="Logo da Loja" className="max-w-44 h-10 rounded-full object-contain object-left" />

                    <button
                        onClick={toggleSidebar}
                        className={`p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white items-center`}
                    >
                        <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <div className={`${!isOpen && 'hidden'}`}>
                    <hr/>
                </div>

                {routes.map((route) => (
                    <NavLink
                        to={route.path}
                        key={route.path}
                        className={`block ml-2 p-2 rounded-l-full w-full text-start ${currentPath === route.path ? 'bg-blue-700 text-white pl-4' : ''}`}
                    >
                        {route.name}
                    </NavLink>
                ))}
            </div>

            {/* Conteúdo */}
            <div className={`${isOpen ? 'ml-64 transition-all duration-200' : 'ml-16 transition-all duration-200'} w-full h-full`}>
                {children}
            </div>
        </div>
    );
};

export default Sidebar;
