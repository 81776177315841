import React from 'react';

const PrimaryButton = ({ children, onClick }) => {
    return (
        <button
            onClick={onClick}
            type='submit'
            className="text-white font-semibold py-2 px-4 rounded-lg w-full bg-blue-700 hover:bg-blue-800 active:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-md transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-95 whitespace-nowrap"
        >
            {children}
        </button>
    );
};

export default PrimaryButton;