import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "./routesConfig";
import Navbar from "./components/NavBar";
import Sidebar from "./components/Sidebar";
import PrivateRoute from "./PrivateRoute.js";
import Login from "./pages/Login.js";
import Auth from "./services/AuthService.js";
import Loading from "./pages/Loading.js";

const AppRoutes = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === "/login";
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (Auth.isTokenExpired(token) && location.pathname !== "/login") {
            window.location.href = "/login";
        } else {
            setLoading(false);
        }
    }, [location]);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            {!isLoginPage && <Navbar />}
            {!isLoginPage ? (
                <Sidebar>
                    <Routes>
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    route.protected ? (
                                        <PrivateRoute element={<route.component />} />
                                    ) : (
                                        <route.component />
                                    )
                                }
                            />
                        ))}
                    </Routes>
                </Sidebar>
            ) : (
                <Routes>
                    <Route path="/login" element={<Login />} />
                </Routes>
            )}
        </>
    );
};

export default AppRoutes;