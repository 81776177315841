import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../axiosConfig';
import ListaProdutos from '../components/ListaProdutos';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { useNavigate } from 'react-router-dom';
import Input from '../components/Input';

export default function NovoPedido() {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(null);
    const [mesas, setMesas] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [mesaSelecionada, setMesaSelecionada] = useState();
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const inputNomeRetirada = useRef(null);
    const inputTelefoneRetirada = useRef(null);
    const inputNomeDelivery = useRef(null);
    const inputTelefoneDelivery = useRef(null);
    const inputLogradouroDelivery = useRef(null);
    const inputNumeroDelivery = useRef(null);
    const inputComplementoDelivery = useRef(null);
    const inputBairroDelivery = useRef(null);
    const inputCidadeDelivery = useRef(null);
    const inputEstadoDelivery = useRef(null);
    const inputCepDelivery = useRef(null);

    const [endereco, setEndereco] = useState({
        logradouro: '',
        bairro: '',
        cidade: '',
        estado: ''
    });

    const getSquareStyles = (option) =>
        `cursor-pointer transition-all duration-500 ${selected
            ? 'w-full h-12'
            : 'w-80 h-80'
        } flex items-center justify-center border-2 rounded-lg ${selected === option ? 'border-blue-500 bg-blue-100' : 'border-gray-300'
        }`;

    async function buscarMesas() {
        try {
            const response = await axiosInstance.get("Mesas");

            if (response.status === 200) {
                setMesas(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function buscarProdutos() {
        try {
            const response = await axiosInstance.get("Produto/GetProdutos");

            if (response.status === 200) {
                setProdutos(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const buscarEnderecoPorCep = async () => {
        const cep = inputCepDelivery.current.value.replace(/\D/g, '');
        if (cep.length === 8) {
            try {
                const response = await axiosInstance.get(`https://viacep.com.br/ws/${cep}/json/`);
                const data = response.data;

                if (!data.erro) {
                    // Atualiza o estado com os dados do endereço
                    setEndereco({
                        logradouro: data.logradouro,
                        bairro: data.bairro,
                        cidade: data.localidade,
                        estado: data.uf
                    });
                } else {
                    alert('CEP não encontrado');
                    setEndereco({ logradouro: '', bairro: '', cidade: '', estado: '' });
                    // msg erro
                }
            } catch (error) {
                alert("Erro ao buscar o endereço. Verifique o CEP e tente novamente.");
                // msg erro
            }
        } else {
            alert("CEP inválido. O CEP deve conter 8 dígitos.");
            // msg erro
        }
    };

    const handleConfirmarPedidoMesa = async () => {
        if (produtosSelecionados.length === 0)
            return alert("Selecione um produto!"); // msg erro

        try {
            const response = await axiosInstance.post("Pedidos/AddProdutosMesa", { MesaId: mesaSelecionada.id, Produtos: produtosSelecionados });
            if (response.status === 200) {
                alert("Pedido confirmado com sucesso"); // msg sucesso
                return navigate("/");
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleConfirmarPedidoRetirada = async () => {
        if (produtosSelecionados.length === 0)
            return alert("Selecione um produto!"); // msg erro

        let nomeCliente = inputNomeRetirada.current.value;
        let telefoneCliente = inputTelefoneRetirada.current.value;

        if (nomeCliente === "")
            return alert("Informe o nome do cliente!"); // msg erro

        if (telefoneCliente === "")
            return alert("Informe o telefone do cliente!"); // msg erro

        let produtosFormatados = produtosSelecionados.map(objeto => ({
            ProdutoId: objeto.produto.id,
            Quantidade: objeto.quantidade,
            Valor: objeto.produto.valor,
            Observacao: objeto.observacao,
        }));

        try {
            const response = await axiosInstance.post("Pedidos/AddRetirada", { Produtos: produtosFormatados, NomeCliente: nomeCliente, Telefone: telefoneCliente.replace(/\D/g, '') });
            if (response.status === 201) {
                alert("Pedido confirmado com sucesso"); // msg sucesso
                return navigate("/");
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
            alert(error.response?.data?.message || "Erro ao criar pedido.");
        }
    };

    const handleConfirmarPedidoDelivery = async () => {
        if (produtosSelecionados.length === 0)
            return alert("Selecione um produto!"); // msg erro

        let nomeCliente = inputNomeDelivery.current.value;
        let telefoneCliente = inputTelefoneDelivery.current.value;
        let logradouroCliente = inputLogradouroDelivery.current.value;
        let numeroCliente = inputNumeroDelivery.current.value;
        let complementoCliente = inputComplementoDelivery.current.value;
        let bairroCliente = inputBairroDelivery.current.value;
        let cidadeCliente = inputCidadeDelivery.current.value;
        let estadoCliente = inputEstadoDelivery.current.value;
        let cepCliente = inputCepDelivery.current.value;

        if (nomeCliente === "")
            return alert("Informe o nome do cliente!"); // msg erro

        if (telefoneCliente === "")
            return alert("Informe o telefone do cliente!"); // msg erro

        if (logradouroCliente === "")
            return alert("Informe o logradouro do cliente!"); // msg erro

        if (numeroCliente === "")
            return alert("Informe o número do cliente!"); // msg erro

        if (bairroCliente === "")
            return alert("Informe o bairro do cliente!"); // msg erro

        if (cidadeCliente === "")
            return alert("Informe o cidade do cliente!"); // msg erro

        let produtosFormatados = produtosSelecionados.map(objeto => ({
            ProdutoId: objeto.produto.id,
            Quantidade: objeto.quantidade,
            Valor: objeto.produto.valor,
            Observacao: objeto.observacao,
        }));

        let cliente = {
            nome: nomeCliente,
            telefone: telefoneCliente.replace(/\D/g, ''),
            logradouro: logradouroCliente,
            numero: numeroCliente,
            complemento: complementoCliente,
            bairro: bairroCliente,
            cidade: cidadeCliente,
            estado: estadoCliente,
            cep: cepCliente,
        }

        try {
            const response = await axiosInstance.post("Pedidos/AddDelivery", { Produtos: produtosFormatados, Cliente: cliente });
            if (response.status === 201) {
                alert("Pedido confirmado com sucesso"); // msg sucesso
                return navigate("/");
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
            alert(error.response?.data?.message || "Erro ao criar pedido.");
        }
    };

    const handleAddProduct = (item, quantidade, observacao) => {
        const novoProduto = {
            produto: {
                id: item.id,
                url_Imagem: item.url_Imagem,
                titulo: item.titulo,
                valor: item.valor
            },
            quantidade,
            observacao
        };

        setProdutosSelecionados(prevProdutos => [...prevProdutos, novoProduto]);
    };

    const handleRemoveProduct = (index) => {
        setProdutosSelecionados(prevProdutos =>
            prevProdutos.filter((_, i) => i !== index)
        );
    };

    return (
        <div className="p-5">
            <div className="flex justify-center gap-8 mb-5">
                <div
                    className={getSquareStyles('presencial')}
                    onClick={() => { setSelected('presencial'); buscarMesas(); setEtapa(1); setMesaSelecionada(); }}
                >
                    <span className="text-lg">Mesas</span>
                </div>

                <div
                    className={getSquareStyles('retirada')}
                    onClick={() => { setSelected('retirada'); setEtapa(1); buscarProdutos(); }}
                >
                    <span className="text-lg">Retirada</span>
                </div>

                <div
                    className={getSquareStyles('delivery')}
                    onClick={() => { setSelected('delivery'); setEtapa(1); buscarProdutos(); }}
                >
                    <span className="text-lg">Delivery</span>
                </div>
            </div>


            {selected === "presencial" &&
                <>
                    <div className="w-full border rounded-[10px] h-12 items-center flex justify-between px-2 mb-4">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2 fa-xl border p-1 rounded cursor-pointer" onClick={() => etapa === 3 ? setEtapa(2) : etapa === 2 ? setEtapa(1) : ''} />

                        {etapa === 1 && <span className='font-regular text-xl'>Selecione a Mesa</span>}
                        {etapa === 2 && <span className='font-regular text-xl'>Selecione os Produtos</span>}
                        {etapa === 3 && <span className='font-regular text-xl'>Confirme os Produtos</span>}

                        <button
                            disabled={mesaSelecionada === undefined || produtosSelecionados.length === 0}
                            onClick={() => etapa !== 3 ? setEtapa(3) : handleConfirmarPedidoMesa()}
                            className={`py-1 text-white font-regular px-4 rounded-lg 
                                bg-blue-700 hover:bg-blue-800 active:bg-blue-900 
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                                shadow-md transition-all duration-200 ease-in-out 
                                transform hover:scale-105 active:scale-95 whitespace-nowrap
                                ${mesaSelecionada === undefined || produtosSelecionados.length === 0 ? 'opacity-50 cursor-not-allowed hover:scale-100' : ''}`}
                        >
                            {etapa === 3 ? "Confirmar" : "Continuar"}
                        </button>
                    </div>

                    {etapa === 1 && // Seleciona Mesa
                        <div className="flex flex-wrap gap-4">
                            {mesas.map((mesa) => (
                                <div
                                    key={mesa.id}
                                    className={`select-none hover:scale-105 shadow-md transition-all duration-200 ease-in-out transform active:scale-95 cursor-pointer rounded-[10px] w-32 h-24 text-white items-center justify-center flex ${mesa.ocupada ? "bg-red-600" : "bg-green-600"}`}
                                    onClick={() => { setMesaSelecionada(mesa); setEtapa(2); buscarProdutos() }}
                                >
                                    Mesa {mesa.numero}
                                </div>
                            ))}
                        </div>
                    }

                    {etapa === 2 && // Escolhe produtos
                        <>
                            <ListaProdutos produtos={produtos} onAddProduct={handleAddProduct} />
                        </>
                    }

                    {etapa === 3 && // Confirma itens
                        <>
                            {produtosSelecionados.map((produto, index) => (
                                <div key={index} className="flex space-x-2 border justify-between rounded-[10px] py-2 px-4 mb-2">
                                    <div className="flex items-center space-x-4">
                                        <img src={produto.produto.url_Imagem} alt="Lanche" className="w-24 h-20 rounded-[10px] object-cover" />
                                        <div className="grid">
                                            <span className="text-lg font-medium">{produto.quantidade} - {produto.produto.titulo}</span>
                                            <span className="text-md font-normal">{produto.observacao}</span>
                                        </div>
                                    </div>
                                    <div className="space-x-2 flex items-center">
                                        <button className="w-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-700 text-white rounded-[10px]">
                                            <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveProduct(index)} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                </>
            }

            {selected === "retirada" &&
                <>
                    <div className="w-full border rounded-[10px] h-12 items-center flex justify-between px-2 mb-4">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2 fa-xl border p-1 rounded cursor-pointer" onClick={() => etapa === 3 ? setEtapa(2) : etapa === 2 ? setEtapa(1) : ''} />

                        {etapa === 1 && <span className='font-regular text-xl'>Selecione os Produtos</span>}
                        {etapa === 2 && <span className='font-regular text-xl'>Confirme os Produtos</span>}
                        {etapa === 3 && <span className='font-regular text-xl'>Dados do Cliente</span>}

                        <button
                            disabled={produtosSelecionados.length === 0}
                            onClick={() => etapa === 1 ? setEtapa(2) : etapa === 3 ? handleConfirmarPedidoRetirada() : setEtapa(3)}
                            className={`py-1 text-white font-regular px-4 rounded-lg 
                                bg-blue-700 hover:bg-blue-800 active:bg-blue-900 
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                                shadow-md transition-all duration-200 ease-in-out 
                                transform hover:scale-105 active:scale-95 whitespace-nowrap
                                ${produtosSelecionados.length === 0 ? 'opacity-50 cursor-not-allowed hover:scale-100' : ''}`}
                        >
                            {etapa === 3 ? "Confirmar" : "Continuar"}
                        </button>
                    </div>

                    {etapa === 1 && // Escolhe produtos
                        <>
                            <ListaProdutos produtos={produtos} onAddProduct={handleAddProduct} />
                        </>
                    }
                    {etapa === 2 && // Confirma itens
                        <>
                            {produtosSelecionados.map((produto, index) => (
                                <div key={index} className="flex space-x-2 border justify-between rounded-[10px] py-2 px-4 mb-2">
                                    <div className="flex items-center space-x-4">
                                        <img src={produto.produto.url_Imagem} alt="Lanche" className="w-24 h-20 rounded-[10px] object-cover" />
                                        <div className="grid">
                                            <span className="text-lg font-medium">{produto.quantidade} - {produto.produto.titulo}</span>
                                            <span className="text-md font-normal">{produto.observacao}</span>
                                        </div>
                                    </div>
                                    <div className="space-x-2 flex items-center">
                                        <button className="w-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-700 text-white rounded-[10px]">
                                            <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveProduct(index)} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                    {etapa === 3 && // Dados entrega
                        <>
                            <div className="mt-6 space-y-2 w-full text-center">
                                <Input placeholder="Informe o Nome" size="medium" referencia={inputNomeRetirada} />
                                <Input placeholder="Informe o Telefone" size="medium" type="tel" referencia={inputTelefoneRetirada} />

                                {/* <span className='pt-5 inline-block text-xl font-semibold'>Tempo de Preparo: 30 a 45 minutos</span> */}
                            </div>
                        </>
                    }
                </>
            }

            {selected === "delivery" &&
                <>
                    <div className="w-full border rounded-[10px] h-12 items-center flex justify-between px-2 mb-4">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2 fa-xl border p-1 rounded cursor-pointer" onClick={() => etapa === 3 ? setEtapa(2) : etapa === 2 ? setEtapa(1) : ''} />

                        {etapa === 1 && <span className='font-regular text-xl'>Selecione os Produtos</span>}
                        {etapa === 2 && <span className='font-regular text-xl'>Confirme os Produtos</span>}
                        {etapa === 3 && <span className='font-regular text-xl'>Dados de Entrega</span>}

                        <button
                            disabled={produtosSelecionados.length === 0}
                            onClick={() => etapa === 1 ? setEtapa(2) : etapa === 3 ? handleConfirmarPedidoDelivery() : setEtapa(3)}
                            className={`py-1 text-white font-regular px-4 rounded-lg 
                                bg-blue-700 hover:bg-blue-800 active:bg-blue-900 
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                                shadow-md transition-all duration-200 ease-in-out 
                                transform hover:scale-105 active:scale-95 whitespace-nowrap
                                ${produtosSelecionados.length === 0 ? 'opacity-50 cursor-not-allowed hover:scale-100' : ''}`}
                        >
                            {etapa === 3 ? "Confirmar" : "Continuar"}
                        </button>
                    </div>

                    {etapa === 1 && // Escolhe produtos
                        <>
                            <ListaProdutos produtos={produtos} onAddProduct={handleAddProduct} />
                        </>
                    }
                    {etapa === 2 && // Confirma itens
                        <>
                            {produtosSelecionados.map((produto, index) => (
                                <div key={index} className="flex space-x-2 border justify-between rounded-[10px] py-2 px-4 mb-2">
                                    <div className="flex items-center space-x-4">
                                        <img src={produto.produto.url_Imagem} alt="Lanche" className="w-24 h-20 rounded-[10px] object-cover" />
                                        <div className="grid">
                                            <span className="text-lg font-medium">{produto.quantidade} - {produto.produto.titulo}</span>
                                            <span className="text-md font-normal">{produto.observacao}</span>
                                        </div>
                                    </div>
                                    <div className="space-x-2 flex items-center">
                                        <button className="w-10 py-2 bg-red-500 hover:bg-red-600 active:bg-red-700 text-white rounded-[10px]">
                                            <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveProduct(index)} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                    {etapa === 3 && // Dados entrega
                        <>
                            <div className="mt-6 space-y-4 w-full text-center">

                                {/* Nome */}
                                <Input
                                    placeholder="Informe o Nome"
                                    size="medium"
                                    referencia={inputNomeDelivery}
                                    className="w-full"
                                />

                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Informe o Telefone"
                                        size="medium"
                                        type="tel"
                                        referencia={inputTelefoneDelivery}
                                        className="w-1/2"
                                    />
                                    <Input
                                        placeholder="Informe o CEP"
                                        size="medium"
                                        type="cep"
                                        referencia={inputCepDelivery}
                                        className="w-1/2"
                                        onBlur={() => buscarEnderecoPorCep()}
                                    />
                                </div>

                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Informe o Logradouro"
                                        size="medium"
                                        value={endereco.logradouro} 
                                        referencia={inputLogradouroDelivery}
                                        className="w-1/2"
                                    />
                                    <Input
                                        placeholder="Número"
                                        size="medium"
                                        type="text"
                                        referencia={inputNumeroDelivery}
                                        className="w-1/2"
                                    />
                                </div>

                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Complemento"
                                        size="medium"
                                        referencia={inputComplementoDelivery}
                                        className="w-1/2"
                                    />
                                    <Input
                                        placeholder="Bairro"
                                        size="medium"
                                        referencia={inputBairroDelivery}
                                        className="w-1/2"
                                        value={endereco.bairro} 
                                    />
                                </div>

                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Cidade"
                                        size="medium"
                                        referencia={inputCidadeDelivery}
                                        className="w-1/2"
                                        value={endereco.cidade} 
                                    />
                                    <Input
                                        placeholder="Estado"
                                        size="medium"
                                        referencia={inputEstadoDelivery}
                                        value={endereco.estado} 
                                        className="w-1/2"
                                    />
                                </div>

                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
}
