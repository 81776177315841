import React from 'react';

const SecondaryButton = ({ children, onClick }) => {
    return (
        <button
            onClick={onClick}
            type='button'
            className="text-gray-700 font-semibold py-2 px-4 rounded-lg w-full bg-gray-300 hover:bg-gray-400 active:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 shadow-md transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-95 whitespace-nowrap"
        >
            {children}
        </button>
    );
};

export default SecondaryButton;
