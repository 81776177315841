import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { formatValue } from '../utils/formatValue';

function Dashboard() {
    const [pedidos, setPedidos] = useState(0);
    const [ranking, setRanking] = useState([]);
    const [faturamento, setFaturamento] = useState(0);
    const [filtroPeriodo, setFiltroPeriodo] = useState('mes');

    useEffect(() => {
        async function buscarDados() {
            try {
                const response = await axiosInstance.get(`Relatorios/Dashboard?periodo=${filtroPeriodo}`);

                if (response.status === 200) {
                    setPedidos(response.data.totalPedidos);
                    setRanking(response.data.rankingPratos);
                    setFaturamento(response.data.totalFaturamento);
                } else {
                    // msg erro
                }
            } catch (error) {
                // msg erro
            }
        }

        buscarDados(); // Chama a função sempre que filtroPeriodo muda
    }, [filtroPeriodo]);

    const handlePeriodoChange = (e) => {
        setFiltroPeriodo(e.target.value);
    };

    return (
        <div className="h-auto p-8 flex flex-col items-center space-y-8">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Dashboard</h1>
            
            <div className="flex items-center space-x-4 mb-8">
                <span className="text-lg font-medium text-gray-700">Filtrar por:</span>
                <select 
                    className="p-2 border border-gray-300 rounded" 
                    value={filtroPeriodo} 
                    onChange={handlePeriodoChange}
                >
                    <option value="mes">Mês</option>
                    <option value="ano">Ano</option>
                </select>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl">
                <div className="bg-white shadow-lg rounded-lg p-6 text-center">
                    <h2 className="text-xl font-semibold text-gray-700">Pedidos Realizados</h2>
                    <p className="text-3xl font-bold text-blue-600 mt-4">{pedidos}</p>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-xl font-semibold text-gray-700 text-center">Top 3 Pratos Mais Pedidos</h2>
                    <ol className="mt-4 text-gray-600 space-y-2 text-center">
                        {ranking.map((prato, index) => (
                            <li key={index}>{prato.totalPedidos} {prato.prato}</li>
                        ))}
                    </ol>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6 text-center">
                    <h2 className="text-xl font-semibold text-gray-700">Faturamento</h2>
                    <p className="text-3xl font-bold text-green-600 mt-4">{formatValue(faturamento)}</p>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
