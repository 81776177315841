import axiosInstance from '../axiosConfig';
import React, { useState } from "react";
import Auth from '../services/AuthService.js';

const Login = () => {
    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const [formData, setFormData] = useState({
        restaurante: "",
        email: "",
        senha: "",
    });

    const [erro, setErro] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setErro(""); // Limpa o erro ao começar a digitar
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const toggleSenhaVisivel = () => {
        setSenhaVisivel(!senhaVisivel);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.restaurante || !formData.email || !formData.senha) {
            setErro("Todos os campos são obrigatórios!");
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post("Auth/admin/token", formData);
            if(response.status === 200){
                Auth.login(response.data);
                // console.log(response.data.restaurante)
            }
        } catch (error) {
            if (error.response) {
                // Erros enviados pela API
                setErro(error.response.data.message || "Erro ao autenticar. Verifique suas credenciais.");
            } else if (error.request) {
                // Erros de rede ou sem resposta do servidor
                setErro("Erro de conexão. Verifique sua internet ou tente novamente mais tarde.");
            } else {
                // Outros erros
                setErro("Erro inesperado. Por favor, tente novamente.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-3">Bem-vindo</h2>
                <p className="text-center text-gray-600 mb-4">Faça login para continuar</p>

                <form onSubmit={handleSubmit} noValidate>
                    {/* Estabelecimento */}
                    <div className="mb-5">
                        <label className="block text-gray-700 font-semibold mb-1">Estabelecimento</label>
                        <input
                            type="text"
                            name="restaurante"
                            placeholder="Digite o nome do estabelecimento"
                            value={formData.restaurante}
                            onChange={handleChange}
                            className={`w-full px-4 py-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 ${erro && !formData.restaurante
                                ? "border-red-500 focus:ring-red-500"
                                : "border-gray-300 focus:ring-indigo-500"
                                }`}
                            required
                        />
                    </div>

                    {/* Email */}
                    <div className="mb-5">
                        <label className="block text-gray-700 font-semibold mb-1">Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Digite seu email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full px-4 py-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 ${erro && !formData.email ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                                }`}
                            required
                        />
                    </div>

                    {/* Senha */}
                    <div className="mb-5 relative">
                        <label className="block text-gray-700 font-semibold mb-1">Senha</label>
                        <div className="relative flex items-center">
                            <input
                                type={senhaVisivel ? "text" : "password"}
                                name="senha"
                                placeholder="Digite sua senha"
                                value={formData.senha}
                                onChange={handleChange}
                                className={`w-full px-4 py-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 ${erro && !formData.senha ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                                    }`}
                                required
                            />
                            <button
                                type="button"
                                onClick={toggleSenhaVisivel}
                                className="absolute right-4 flex items-center text-gray-500 hover:text-indigo-500 focus:outline-none"
                            >
                                {senhaVisivel ? "👁️" : "👁️‍🗨️"}
                            </button>
                        </div>
                    </div>


                    {/* Mensagem de Erro */}
                    {erro && <p className="text-red-500 text-sm mb-5 text-center">{erro}</p>}

                    {/* Botão de Login */}
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full font-semibold py-3 px-4 rounded-lg shadow-md transition-all duration-200 ${loading
                                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                : "bg-indigo-500 text-white hover:bg-indigo-600 hover:shadow-lg"
                                }`}
                        >
                            {loading ? "Entrando..." : "Entrar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
