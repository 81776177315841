import React, { useState, useEffect, useRef } from 'react';

const SelectAutocomplete = ({
    value,
    onChange,
    placeholder,
    size = 'medium',
    options = []
}) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [inputValue, setInputValue] = useState(value?.titulo || '');
    const inputRef = useRef(null);

    const sizeClasses = {
        small: 'text-sm py-1 px-2',
        medium: 'text-base py-2 px-3',
        large: 'text-lg py-3 px-4',
    };

    useEffect(() => {
        const filtered = options.filter((option) =>
            option.titulo.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filtered);
    }, [inputValue, options]);

    const handleSelectOption = (option) => {
        setInputValue(option.titulo);
        onChange(option);
        setShowOptions(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e);
        setShowOptions(true);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={inputRef}>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                className={`${sizeClasses[size]} rounded-lg w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-sm`}
                onFocus={() => setShowOptions(true)}
            />

            {showOptions && filteredOptions.length > 0 && (
                <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 rounded-lg shadow-lg">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onClick={() => handleSelectOption(option)}
                            className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                        >
                            {option.titulo}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectAutocomplete;
