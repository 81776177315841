import { useState, useRef, useEffect } from "react";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import Modal from "../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleXmark,
    faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import axiosInstance from '../axiosConfig';
import Input from "../components/Input";
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { formatValue } from '../utils/formatValue';
import SelectAutocomplete from "../components/SelectAutoComplete";
import { faPrint, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { QRCodeCanvas } from 'qrcode.react';
import { jwtDecode } from 'jwt-decode';

function Mesas() {
    const [mesas, setMesas] = useState([]);
    const [itensMesa, setItensMesa] = useState([]);
    const [menuVisible, setMenuVisible] = useState(false);
    const [idMenu, setIdMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedMesa, setSelectedMesa] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [isModalAddMesa, setIsModalAddMesa] = useState(false);
    const [isModalAddProdutoMesa, setIsModalAddProdutoMesa] = useState(false);
    const menuRef = useRef(null);
    const inputMesa = useRef(null);
    const inputCapacidadeMesa = useRef(null);
    const inputQtdAddProduto = useRef(null);
    const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const [itemConfirmDelete, setItemConfirmDelete] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const [produtos, setProdutos] = useState([]);
    const [qrModalVisible, setQrModalVisible] = useState(false);
    const qrCodeRef = useRef();

    useEffect(() => {
        buscarMesas();
    }, []);

    async function buscarMesas() {
        try {
            const response = await axiosInstance.get("Mesas");

            if (response.status === 200) {
                setMesas(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function buscarProdutos() {
        try {
            const response = await axiosInstance.get("Produto");

            if (response.status === 200) {
                setProdutos(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function buscarItensMesa(mesaId) {
        try {
            const response = await axiosInstance.post("Mesas/ItensMesa", { IdMesa: mesaId.id });

            if (response.status === 200) {
                setItensMesa(response.data);
                setModalVisible(true);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleContextMenu = (id) => (event) => {
        event.preventDefault();
        const { clientX: x, clientY: y } = event;

        const menuHeight = menuRef.current ? menuRef.current.offsetHeight : 0;
        const adjustedY = y + menuHeight > window.innerHeight ? y - menuHeight : y;

        setMenuPosition({ x, y: adjustedY });
        setMenuVisible(true);
        setIdMenu(id);
    };

    const handleClickOutTable = () => {
        setMenuVisible(false);
    };

    async function adicionarProdutoMesa() {
        let quantidade = inputQtdAddProduto.current.value;

        if (quantidade < 1) {
            // msg erro
            return alert("Quantidade Inválida")
        }

        if (produtoSelecionado === "" || produtoSelecionado === null) {
            // msg erro
            return alert("Selecione um produto")
        }

        try {
            const response = await axiosInstance.post("Pedidos/AddProdutoMesa", { MesaId: selectedMesa.id, ProdutoId: produtoSelecionado.id, Quantidade: quantidade, Valor: produtoSelecionado.valor });

            if (response.status === 200) {
                // msg sucesso
                closeModal();
                setProdutoSelecionado('');
                buscarItensMesa({ id: selectedMesa.id });
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const formMesaSubmit = (event) => {
        event.preventDefault();
        let numero = parseInt(inputMesa.current.value, 10);
        let capacidade = parseInt(inputCapacidadeMesa.current.value, 10);

        if (!mesas.some((mesa) => mesa.numero === numero)) {
            criarMesa(numero, capacidade);
        }
        else {
            // msg erro
            alert("Já existe uma mesa com esse número")
        }
    };

    async function criarMesa(numero, capacidade) {
        try {
            const response = await axiosInstance.post("Mesas/NovaMesa", { Numero: numero, Capacidade: capacidade });

            if (response.status === 201) {
                // msg sucesso
                closeModal();

                const newMesa = {
                    id: response.data,
                    numero: numero,
                    ocupada: false,
                };

                setMesas([...mesas, newMesa].sort((a, b) => a.id - b.id));
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    async function inativarMesa(id) {
        try {
            const response = await axiosInstance.delete("Mesas/InativarMesa/" + id);

            if (response.status === 204) {
                const updatedMesas = mesas.filter(mesa => mesa.id !== id);
                setMesas(updatedMesas);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const handleDeleteMesa = (id) => {
        const mesa = mesas.find(mesa => mesa.id === id);

        if (mesa && !mesa.ocupada) {
            inativarMesa(id);
        } else {
            alert("A mesa não está vazia. Não é possível excluir.");
        }
    };

    const handleOpenComanda = (mesa) => {
        setSelectedMesa(mesa);
        buscarItensMesa(mesa);
    };

    const handleDeleteProduct = (productIndex) => {
        setModalConfirmDelete(true);
        setItemConfirmDelete(itensMesa[0].itensPedido[productIndex]);
    };

    const handleConfirmDelete = async () => {
        setModalConfirmDelete(false);

        try {
            await axiosInstance.put("Pedidos/RemoverProduto/" + itemConfirmDelete.id);
            buscarItensMesa({ id: selectedMesa.id });
        } catch (error) {
            // msg erro
        }
    };

    const handleOptionChange = (option) => {
        setProdutoSelecionado(option);
    };

    const handleCloseComanda = async (mesa) => {
        try {
            const response = await axiosInstance.put("Mesas/FecharComanda", { IdMesa: mesa });

            if (response.status === 204) {
                // msg sucesso
                const updatedMesas = mesas.map(mesa =>
                    mesa.id === selectedMesa.id
                        ? { ...mesa, ocupada: false }
                        : mesa
                );
                setMesas(updatedMesas);
                setModalVisible(false);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        }
    };

    const openAddMesa = () => setIsModalAddMesa(true);
    const openAddProduto = () => setIsModalAddProdutoMesa(true);

    const closeModal = () => {
        setIsModalAddMesa(false);
        setIsModalAddProdutoMesa(false);
        setModalConfirmDelete(false);
    };

    const fullDomain = window.location.hostname;
    const baseDomain = fullDomain.split('.')[1];
    const token = localStorage.getItem('token'); 
    let lanchonete = '';
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            lanchonete = decodedToken.restaurante;
        } catch (error) {
            console.error('Erro ao parsear o token:', error);
        }
    }

    const handlePrint = () => {
        const qrCanvas = qrCodeRef.current.querySelector('canvas');
        if (qrCanvas) {
            const qrImage = qrCanvas.toDataURL('image/png'); // Converte para base64

            // Conteúdo da nova aba
            const printContent = `
                <html>
                    <head>
                        <title>Imprimir QR Code</title>
                        <style>
                            body {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                margin: 0;
                                font-family: Arial, sans-serif;
                                text-align: center;
                            }
                            .container {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }
                            h1 {
                                margin-bottom: 20px;
                                font-size: 1.5rem;
                            }
                            img {
                                width: 256px;
                                height: 256px;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <h1>QR Code - Mesa ${selectedMesa?.numero}</h1>
                            <img src="${qrImage}" alt="QR Code da Mesa ${selectedMesa?.numero}" />
                        </div>
                        <script>
                            window.onload = function() {
                                window.print();
                                setTimeout(() => window.close(), 100); // Fecha a aba após a impressão
                            }
                        </script>
                    </body>
                </html>
            `;

            // Abre uma nova aba temporária para impressão
            const printWindow = window.open('', '_blank');
            printWindow.document.write(printContent);
            printWindow.document.close();
        }
    };


    return (
        <>
            <ConfirmDeleteModal
                isOpen={modalConfirmDelete}
                onClose={closeModal}
                onConfirm={handleConfirmDelete}
                itemName={itemConfirmDelete && itemConfirmDelete.produto && itemConfirmDelete.produto.titulo}
            />
            <Modal isOpen={isModalAddMesa} onClose={closeModal}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">Nova Mesa</span>
                    <button className="" onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
                <form onSubmit={formMesaSubmit}>
                    <div className="space-y-2">
                        <div className="space-x-2 flex">
                            <Input placeholder="Informe o número da mesa" size="medium" referencia={inputMesa} />
                            <Input placeholder="Informe a capacidade da mesa" size="medium" referencia={inputCapacidadeMesa} />
                        </div>
                    </div>
                    <div className="flex items-center justify-between space-x-2 mt-3">
                        <SecondaryButton onClick={() => closeModal()}>
                            Cancelar
                        </SecondaryButton>
                        <PrimaryButton>
                            Cadastrar
                        </PrimaryButton>
                    </div>
                </form>
            </Modal>
            <Modal isOpen={isModalAddProdutoMesa} onClose={closeModal} zIndex={35}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">Novo Produto</span>
                    <button className="" onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
                <div className="space-2 space-y-2">
                    <SelectAutocomplete
                        value={produtoSelecionado}
                        onChange={handleOptionChange}
                        placeholder="Selecione um produto"
                        options={produtos}
                        size="medium"
                    />
                    <Input placeholder="Quantidade" size="medium" referencia={inputQtdAddProduto} type={"number"} />
                </div>
                <div className="flex items-center justify-between space-x-2 mt-3">
                    <SecondaryButton onClick={() => closeModal()}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton onClick={() => adicionarProdutoMesa()}>
                        Adicionar
                    </PrimaryButton>
                </div>
            </Modal>

            <div className="p-5" onClick={handleClickOutTable}>
                <div className="flex flex-wrap justify-between">
                    <div className="flex items-center">
                        <span className="text-xl font-semibold">Mesas:</span>
                    </div>
                    <div className="flex space-x-2 mb-2">
                        <PrimaryButton onClick={() => openAddMesa()}>
                            Nova Mesa
                        </PrimaryButton>
                    </div>
                </div>

                <div className="flex flex-wrap gap-4">
                    {mesas.map((mesa) => (
                        <div
                            key={mesa.id}
                            className={`select-none hover:scale-105 shadow-md transition-all duration-200 ease-in-out transform active:scale-95 cursor-pointer rounded-[10px] w-32 h-24 text-white items-center justify-center flex ${mesa.ocupada ? "bg-red-600" : "bg-green-600"}`}
                            onContextMenu={handleContextMenu(mesa.id)}
                            onClick={() => handleOpenComanda(mesa)}
                        >
                            Mesa {mesa.numero}
                        </div>
                    ))}
                    {menuVisible && (
                        <ul
                            ref={menuRef}
                            className="absolute bg-white shadow-xl rounded space-y-2 border"
                            style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
                        >
                            <li className="p-2 px-4 hover:bg-gray-200 cursor-pointer rounded" onClick={() => handleDeleteMesa(idMenu)}>Excluir</li>
                        </ul>
                    )}
                </div>
            </div>

            <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
                <div className="text-black mb-2 flex justify-between items-center">
                    <span className="text-xl font-semibold">Mesa {selectedMesa?.numero}</span>
                    <div className="flex justify-end mb-2">
                        <button
                            onClick={() => setQrModalVisible(true)}
                            className="bg-blue-700 hover:bg-blue-800 text-white text-sm py-1 px-2 rounded shadow flex items-center"
                        >
                            <FontAwesomeIcon icon={faQrcode} className="mr-1" />
                            Ver QR Code
                        </button>
                    </div>
                    <button onClick={() => setModalVisible(false)}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>
                <div className="rounded-full bg-gray-300 my-2" style={{ height: '2px' }} />

                <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold">Produtos:</span>
                    <button
                        onClick={() => { openAddProduto(); buscarProdutos() }}
                        className="bg-blue-700 hover:bg-blue-800 active:bg-blue-900 text-white text-sm py-1 px-2 rounded shadow"
                    >
                        Adicionar Produto
                    </button>
                </div>

                <div className="space-y-2 my-4">
                    {itensMesa.length > 0 ? (
                        <ul>
                            {itensMesa.map((pedido, pedidoIndex) => (
                                pedido.itensPedido.map((item, itemIndex) => (
                                    <li key={`${pedidoIndex}-${itemIndex}`} className="flex justify-between items-center mt-2">
                                        <span className="text-lg">{item.quantidade} {item.produto.titulo} - {formatValue(item.produto.valor)}</span>
                                        <div className="space-x-4">
                                            <button onClick={() => handleDeleteProduct(itemIndex)} className="pr-1 text-red-500 hover:text-red-600 hover:scale-110 transition-all duration-200 ease-in-out transform">
                                                <FontAwesomeIcon icon={faTrashCan} className="text-2xl" />
                                            </button>
                                        </div>
                                    </li>
                                ))
                            ))}
                        </ul>
                    ) : (
                        <p>A comanda está vazia.</p>
                    )}
                </div>

                {/* Cálculo do Total */}
                <div className="flex justify-end text-lg font-semibold mt-4">
                    <span>Total: {formatValue(itensMesa.reduce((acc, pedido) => {
                        const totalPedido = pedido.itensPedido.reduce((subtotal, item) => subtotal + (item.produto.valor * item.quantidade), 0);
                        return acc + totalPedido;
                    }, 0))}</span>
                </div>

                <div className="flex items-center justify-between space-x-2 mt-3">
                    <SecondaryButton onClick={() => setModalVisible(false)}>Cancelar</SecondaryButton>
                    <PrimaryButton onClick={() => handleCloseComanda(selectedMesa.id)}>Fechar Comanda</PrimaryButton>
                </div>
            </Modal>



            <Modal isOpen={qrModalVisible} onClose={() => setQrModalVisible(false)}>
                <div className="text-black flex justify-between items-center mb-4">
                    <span className="text-xl font-semibold">QR Code da Mesa {selectedMesa?.numero}</span>
                    <button onClick={() => setQrModalVisible(false)}>
                        <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                    </button>
                </div>

                {/* QR Code */}
                <div ref={qrCodeRef} className="flex justify-center my-4">
                    <QRCodeCanvas
                        value={`https://sistema.${baseDomain}.com.br/qrcode/${lanchonete}/${selectedMesa?.id}`}
                        size={256}
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="H"
                    />
                </div>

                {/* Botão de impressão */}
                <div className="flex justify-end">
                    <button
                        onClick={handlePrint}
                        className="bg-green-600 hover:bg-green-700 text-white py-1 px-3 rounded flex items-center"
                    >
                        <FontAwesomeIcon icon={faPrint} className="mr-2" />
                        Imprimir QR Code
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default Mesas;
