import React, { useState } from 'react';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import Input from "../components/Input.js";
import { formatValue } from '../utils/formatValue.js';

function ListaProdutos({ produtos, onAddProduct }) {
    const [isModalAddProduct, setIsModalAddProduct] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [quantidade, setQuantidade] = useState(1);
    const [observacao, setObservacao] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const openModal = (item) => {
        setSelectedItem(item);
        setIsModalAddProduct(true);
    };

    const closeModal = () => {
        setIsModalAddProduct(false);
        setSelectedItem(null);
        setQuantidade(1);
        setObservacao("");
        setErrorMessage(""); // Limpa mensagem de erro
    };

    const handleAddProduct = () => {
        if (quantidade < 1) {
            setErrorMessage("A quantidade deve ser maior que zero.");
            return;
        }

        onAddProduct(selectedItem, quantidade, observacao);
        closeModal();
    };

    const handleQuantityChange = (value) => {
        if (value < 1) {
            setErrorMessage("A quantidade deve ser maior que zero.");
            setQuantidade(1);
        } else {
            setErrorMessage("");
            setQuantidade(value);
        }
    };

    const incrementQuantity = () => {
        setQuantidade((prevQuantidade) => prevQuantidade + 1);
        setErrorMessage("");
    };

    const decrementQuantity = () => {
        if (quantidade > 1) {
            setQuantidade((prevQuantidade) => prevQuantidade - 1);
            setErrorMessage("");
        } else {
            setErrorMessage("A quantidade deve ser maior que zero.");
        }
    };

    return (
        <>
            {Object.keys(produtos).map(categoria => (
                <div key={categoria} className='mt-4'>
                    <span className='text-lg font-bold'>{categoria}:</span>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {produtos[categoria].map((item) => (
                            <div key={item.id} onClick={() => openModal(item)} className="bg-gray-200 rounded-[10px] text-lg text-center h-32 flex cursor-pointer hover:shadow-md hover:bg-gray-300 transition-all duration-200 ease-in-out transform">
                                <div className="w-1/2">
                                    <img
                                        src={item.url_Imagem}
                                        alt={item.titulo}
                                        className="w-full h-full object-cover rounded-[10px] transition-opacity duration-300"
                                    />
                                </div>

                                <div className="h-full w-full flex items-center justify-center">
                                    <div>
                                        <span className='block font-bold text-xl'>{item.titulo}</span>
                                        <span className='block'>{formatValue(item.valor)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {isModalAddProduct && (
                <Modal isOpen={isModalAddProduct} onClose={closeModal}>
                    <div className="text-black mb-2 flex justify-between items-center">
                        <span className="text-xl font-semibold">Adicionar Produto</span>
                        <button onClick={closeModal}>
                            <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                        </button>
                    </div>
                    <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />

                    <div className="space-y-2">
                        <div className="rounded-[10px] text-lg text-center h-32 flex">
                            <div className="w-1/2">
                                <img
                                    src={selectedItem?.url_Imagem}
                                    alt={selectedItem?.titulo}
                                    className="w-full h-full object-contain rounded-[10px]"
                                />
                            </div>

                            <div className="h-full w-full flex justify-end items-center">
                                <div>
                                    <span className='block font-bold text-xl'>{selectedItem?.titulo}</span>
                                    <span className='block'>{formatValue(selectedItem?.valor)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center space-x-2">
                            <button onClick={decrementQuantity} className="p-2 bg-gray-300 rounded-lg">-</button>
                            <Input
                                type="number"
                                placeholder="Quantidade"
                                value={quantidade}
                                onChange={(e) => handleQuantityChange(parseInt(e))}
                                min={1}
                                className="w-16 text-center"
                            />
                            <button onClick={incrementQuantity} className="p-2 bg-gray-300 rounded-lg">+</button>
                        </div>

                        {errorMessage && (
                            <div className="text-red-500 text-sm">{errorMessage}</div>
                        )}

                        <textarea
                            placeholder="Adicione uma observação"
                            value={observacao}
                            onChange={(e) => setObservacao(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>

                    <div className="flex items-center justify-between space-x-2 mt-3">
                        <SecondaryButton onClick={closeModal}>Cancelar</SecondaryButton>
                        <PrimaryButton onClick={handleAddProduct}>Adicionar</PrimaryButton>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ListaProdutos;
