// src/routesConfig.js
import Inicio from "./pages/Inicio";
import MeusPedidos from "./pages/MeusPedidos";
import GestaoCardapio from "./pages/GestaoCardapio";
import Mesas from "./pages/Mesas";
import NovoPedido from "./pages/NovoPedido";

const routes = [
    { path: "/", name: "Início", component: Inicio, protected: true },
    { path: "/pedidos", name: "Meus Pedidos", component: MeusPedidos, protected: true  },
    { path: "/gestaocardapio", name: "Gestão de Cardápio", component: GestaoCardapio, protected: true  },
    { path: "/mesas", name: "Mesas", component: Mesas, protected: true  },
    { path: "/novoPedido", name: "Novo Pedido", component: NovoPedido, protected: true  },
];

export default routes;