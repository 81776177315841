import React from 'react';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, itemName }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} zIndex={35}>
            <div className="text-black mb-2 flex justify-between items-center">
                <span className="text-xl font-semibold">Confirmação de Exclusão</span>
                <button className="" onClick={() => onClose()}>
                    <FontAwesomeIcon icon={faCircleXmark} className="text-3xl" />
                </button>
            </div>
            <div className="rounded-full bg-gray-300 my-2" style={{ height: '2.5px' }} />
            Você tem certeza que deseja excluir {itemName}?
            <div className="flex items-center justify-between space-x-2 mt-3">
                <SecondaryButton onClick={() => onClose()}>
                    Cancelar
                </SecondaryButton>
                <PrimaryButton onClick={onConfirm}>
                    Confirmar
                </PrimaryButton>
            </div>
        </Modal>
    );
};

export default ConfirmDeleteModal;
